/*
	Carousel script that accepts two or more slides
	Dependencies: jQuery 3.3.1 or higher, Flickity PACKAGED v2.1.2
	Initialize: AppWBZGV.flickityElement.init('.carousel-holder');
*/

$(document).ready(function() {


	AppWBZGV.flickityElement = (function() {
		
		var flickitySlider,
				amountOfSlides;
		
		return {
			
			flickitySingleSlide: function() {
				flickitySlider.addClass('single-slide1');
				flickitySlider.flickity({
					wrapAround: false
				});
			},
			
			flickityWrapAround: function() {
				flickitySlider.removeClass('single-slide');
			},
			
			init: function(carouselElement) {
				
				let context = this;
				
				$(carouselElement).each(function(index, item) {
				
					flickitySlider = $(item).find('.carousel');
					amountOfSlides = flickitySlider.find('.carousel-cell');
					
					if (flickitySlider) {
						 
						var bounds = [
						  { min: 0, max: 699, func: context.flickitySingleSlide },
						  { min: 700, func: context.flickityWrapAround }
						];
				
						if (amountOfSlides && amountOfSlides.length > 2) {
				
							flickitySlider.flickity({
								freeScroll: true,
								wrapAround: true,
								accessibility: true
							});
								
							var resizeFn = function(){
						  	var lastBoundry; // cache the last boundry used
						    return function() {
						      var width = window.innerWidth; // get the window's inner width
						      var boundry, min, max;
						      for(var i = 0; i < bounds.length; i++) {
						        boundry = bounds[i];
						        min = boundry.min || Number.MIN_VALUE;
						        max = boundry.max || Number.MAX_VALUE;
						        if (width > min && width < max && lastBoundry !== boundry) {
						          lastBoundry = boundry;
						          return boundry.func.call(boundry);           
						        }
						      }
						    }
						  }
							  
							$(window).resize(resizeFn());
								
						} // amount of slides > 2
						
						else if (amountOfSlides && amountOfSlides.length < 3) {
							let $carousel = $(item).closest('carousel-holder');
							$carousel = $($carousel.prevObject[0]);
							$carousel.addClass('two-slides-view');
							flickitySlider.flickity({
								draggable: '>1',
								freeScroll: false,
								groupCells: 2,
								pageDots: false,
								wrapAround: false,
								contain: true
							});
						}
						
						if (amountOfSlides && amountOfSlides.length == 1) {
							let $carousel = $(item).closest('carousel-holder');
							$carousel = $($carousel.prevObject[0]);
							$carousel.removeClass('two-slides-view');
							$carousel.addClass('one-slide-view');
						}
						
					} // flickitySlider.length
				
				});
				
			} // init
			
		} // return

	})(); // flickityElement
	
});